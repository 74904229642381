import React, { useState, useEffect, useRef } from 'react';
import { BsCartCheckFill } from 'react-icons/bs';

// COMPONENTS
import CountDownTImmer from '../components/CountDownTImmer';

// ASSETS
import {
  cod, hourSale, timeOffer, warranty,
  beeVenon1,
  beeVenon4,
  beeVenon8,
  beeVenon7,
  beeVenon10,
  beeVenon11,
  beeVenon12,
} from '../assets/images';
import { Footer, Form, TestimoneyCard } from '../components';

const Home = () => {
  const [targetTime, setTargetTime] = useState('2023-11-12T12:00:00');
  const formRef = useRef(null);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 12);
    const targetDate = currentDate.toISOString().slice(0, 19).replace('T', ' ');
    setTargetTime(targetDate);
  }, []);

  const testimonies = [
    {
      name: 'Mrs. Adebola, Lagos',
      date: '17-05-2023',
      text: 'I&apos;ve struggled with arthritis for years, but this cream has been a game-changer. My knees feel so much better!',
    },
    {
      name: 'Chinedu, Abuja',
      date: '16-05-2024',
      text: 'I was skeptical at first, but after a few days of use, the difference is clear. No more stiffness!',
    },
    {
      name: 'Fatima, Kano',
      date: '04-06-2024',
      text: 'This cream works wonders! I can finally enjoy my daily walks without pain',
    },
    {
      name: 'Bimbo, Ibadan',
      date: '01-07-2024',
      text: 'I&apos;ve struggled with severe joint pain for years, and nothing seemed to work. After just a few days of using the Bee Venom Arthritis Care Cream, I felt a huge difference! I can now move freely without pain. This cream is truly a miracle in a jar!',
    },
    {
      name: 'Ebuka, Anambra',
      date: '11-10-2024',
      text: 'As a retired athlete, arthritis had made my daily life difficult. I tried countless treatments, but nothing gave me the lasting relief I needed. Bee Venom Arthritis Care Cream has been a game-changer for me. I&apos;m back to enjoying my morning walks pain-free!',
    },
  ];

  return (
    <>
      <main className=" w-full flex flex-col items-center">
        <section className="w-[95%] md:w-[50%] flex flex-col items-center gap-3">
          <CountDownTImmer targetDate={targetTime} />
          <h1 className="text-2xl text-[#3A82E4] font-bold text-center">
            {/* <h1 className="text-2xl text-[#4CAF50] font-bold text-center"> */}
            BEE VENOM ARTHRITIS CREAM - A Permanent Solution to Arthritis
          </h1>
          <img src={hourSale} alt="sales banner" className=" my-3 w-[40%] " />
          <span className=" font-bold">For Enquries, Call/WhatsApp 09019520232</span>
          <h4 className=" font-bold italic text-center">
            Limited-Time Offer:
            {' '}
            with
            {' '}
            <span className="font-bold text-xl text-red-500"> GET RELIEF TODAY! FREE DELIVERY NATIONWIDE!</span>
          </h4>
          <img src={beeVenon1} alt="main" />
          <div className="w-full h-full flex">
            <img src={beeVenon4} alt="fuelEco5" className=" w-[50%] rounded-l-md" />
            <img src={beeVenon8} alt="fuelEco4" className=" w-[50%] rounded-r-md" />
          </div>
          <button
            className="text-xl bg-[#2fbe00] w-[40%] py-2 rounded-md font-bold text-white hover:bg-green-800 my-2 flex  justify-center gap-1"
            type="button"
            onClick={scrollToForm}
          >
            <BsCartCheckFill />
            ORDER NOW
          </button>
          <p>
            <span className=" font-bold block text-center">
              Are You Tired of Arthritis Pain, Painful Joints and Stiffness?
            </span>
            <span className="font-bold text-2xl underline text-red-500 block text-center">
              Introducing
            </span>
            <span className="block text-center text-[#3A82E4] font-bold text-4xl my-4">
              Wiyun Bee Venom Arthritis Care Cream
            </span>
            <span className="block text-center font-bold italic">
              Working closely with leading experts in the field of naturopathy and apitherapy,
              we have developed a revolutionary thatharnesses the healing powers of natural
              to fight a wide range of ailments.
            </span>
            <br />
            <span className="block text-center font-bold italic">
              This revolutionary cream combines the natural healing properties of Bee Venom,
              Arnica Flower Extract, and Sophora Flavescens Root Extract to bring you quick relief,
              enhanced recovery, and long-lasting comfort.
            </span>
            <br />
            <span className="bg-red-500 text-white px-2 text-center block font-bold">
              Approved by orthopedic doctors, it eliminates pain within 5
              minutes and cures arthritis in 4 weeks.
            </span>
          </p>
        </section>
        <section className="w-[95%] md:w-[50%]">
          <h4 className="my-4 w-full bg-[#3A82E4] text-white text-center py-2 font-black text-xl">Why Choose Bee Venom Arthritis Cream?</h4>
          <ul className="flex flex-col items-start gap-2">
            <li className=" text-start">
              <span className="font-bold text-lg mr-2">Relieve Joint Pain and Discomfort:</span>
              Instantly soothe aching joints and reduce inflammation.
            </li>
            <li className=" text-start">
              <span className="font-bold text-lg mr-2">Repair and Support Joint Recovery:</span>
              Stimulate natural healing and repair damaged tissues for better joint function.
            </li>
            <li className=" text-start">
              <span className="font-bold text-lg mr-2">Enhance Mobility:</span>
              Say goodbye to stiffness—move freely and confidently again!
            </li>
            <li className=" text-start">
              <span className="font-bold text-lg mr-2">Strengthen Skin Barrier:</span>
              Moisturize and protect your skin while caring for your joints.
            </li>
          </ul>
        </section>
        <section className="w-[95%] md:w-[50%]">
          <div className="w-full h-full flex">
            <img src={beeVenon7} alt="fuelEco5" className=" w-[100%] rounded-md" />
          </div>
        </section>
        <section className="w-[95%] md:w-[50%]">
          <h4
            className="my-4 w-full bg-[#3A82E4] text-white text-center py-2 font-black text-xl"
          >
            Natural Ingredients for Effective Results
          </h4>
          <ul className="flex flex-col items-start gap-2">
            <li className=" text-start">
              <span className="font-bold text-lg mr-2">Bee venom filtrate:</span>
              Bee venom is poison from New Zealand honey bees. It is a method of using ether
              anesthesia to stimulate bees to release bee venom. It does not harm life.
              The main component of the bee venom filter is melittin, which is a biologically
              active protein, has strong anti-inflammatory and analgesic effects and can
              effectively eliminate the pain and swelling of arthritis.
              <br />
            </li>
            <li className=" text-start">
              <span className="font-bold text-lg mr-2">Glucosamine:</span>
              The glucosamine component plays an important role in WIYUNBee Venom Instant Ultra
              Strength Pain Relief Therapy Cream Glucosamine is a naturally occurring compound
              found in the body that is important for the maintenance and repair of cartilage
              and other connective tissues. It has been shown to reduce inflammation and pain,
              improve joint mobility and support the repair of damaged cartilage and bone tissue.
            </li>
            <li className=" text-start">
              <span className="font-bold text-lg mr-2">Arnica Extract:</span>
              Arnica flower extract is a key ingredient in WIYUNBee Venom Instant
              Ultra Strength Pain Relief Therapy Cream.This herbal extract has powerful
              anti-inflammatory and analgesic properties, making it ideal for people
              suffering from joint and bone pain. Arnica Montana Flower Extract helps
              to reduce inflammation and pain while promoting blood circulation
              and wound healing.
            </li>
            <li className=" text-start">
              <span className="font-bold text-lg mr-2">Vitamin K2:</span>
              Vitamin K2 is a key ingredient in Bee Venom Joint and Bone Therapy Cream.
              Vitamin K2 is a fat-soluble vitamin that plays an important role in the body&apos;s
              ability to use calcium. It helps to regulate calcium metabolism, helping to
              ensure it is used effectively for bone and joint health.
            </li>

          </ul>
        </section>
        <section className=" w-[95%] md:w-[50%] flex flex-col justify-center items-center">
          <h4 className="mt-6 text-3xl font-bold text-center text-red-500">WHY IS BEE VENOM JOINT AND BONE THERAPY CREAM THE BEST CHOICE</h4>
          {/* <iframe
            src="https://drive.google.com/file/d/1ye-7aW_wNMpy4NKE2ThSJrqgwmG0Rlbcc/preview"
            className="w-[90%] h-80 border-2 border-[#3A82E4] rounded-lg dark:border-gray-700 mb-3"
            allow="autoplay"
            title="Fuel Economizer"
          />
          <iframe
            src="https://drive.google.com/file/d/1fhwOCSfsFEZxplJb-4zN86jydeelUJvhc/preview"
            className="w-[90%] h-80 border-2 border-[#3A82E4] rounded-lg dark:border-gray-700 mb-3"
            allow="autoplay"
            title="Fuel Economizer fixing"
          /> */}

          <div>
            <img src={beeVenon12} alt="main" className="mb-2 w-full h-full rounded-md" />
            <img src={beeVenon10} alt="main" className="mt-2 w-full h-full rounded-md" />
          </div>
        </section>
        <section className="w-[95%] md:w-[50%] flex flex-col items-center my-4">
          <img src={timeOffer} alt="limited offer" className=" w-[40%] my-3" />
          <div className="flex flex-col items-center">
            <h3 className="text-2xl text-red-600 font-bold">DON&apos;T MISS OUT ON THIS SPECIAL PROMO!</h3>
            <div className=" my-4 flex flex-col items-center">
              <span className=" font-bold line-through">REGULAR PRICE: N20,500 PER ONE</span>
              <span className="font-bold text-4xl text-red-600">Promo Price: N16,500</span>
            </div>
            <span className="block font-bold text-lg italic underline text-center">GET FREE DELIVERY NATION WIDE TO YOUR DOOR STEP</span>
            <span className="font-bold text-4xl text-red-600 my-4 block text-center">SPECIAL OFFER: GET 2 FOR N30,500</span>
          </div>
          <button
            className="text-xl bg-[#2fbe00] w-[40%] py-2 rounded-md font-bold text-white hover:bg-green-800 my-2 flex  justify-center gap-1"
            type="button"
            onClick={scrollToForm}
          >
            <BsCartCheckFill />
            ORDER NOW
          </button>
        </section>
        <section className="w-[95%] md:w-[50%] flex flex-col items-center">
          <h4
            className="my-4 w-full bg-[#3A82E4] text-white text-center py-2 font-black text-xl "
          >
            How to Use Bee Venom Arthritis Care Cream
          </h4>
          <div className="flex flex-col items-start font-bold text-lg">
            <span className=" text-start">
              1. Apply a small amount to the affected area.
            </span>
            <span className=" text-start">
              2. Massage gently until fully absorbed.
            </span>
            <span className=" text-start">
              3. Use twice daily for the best results.
            </span>
          </div>
          <div className="flex flex-col items-center justify-center mt-4">
            <span className=" text-2xl text-red-600 font-bold">Gurantee:</span>
            <p className=" font-bold text-center">
              WIYUN Bee Venom Instant Ultra Strength Pain Relief Therapy Cream contains six proven,
              powerful ingredients that have been used to effectively treat arthritis and
              bone-related conditions in millions of people around the world.

            </p>
          </div>
          <img src={warranty} alt="one year warranty" className="w-[40%]" />
        </section>
        <section className="w-[95%] md:w-[50%] border-2 border-[#3A82E4] rounded-md flex flex-col items-center">
          <div className="flex flex-col justify-center items-center w-[80%] " ref={formRef}>
            <p className="text font-bold my-3 text-center">
              Please Do Not order for Jokes, Dont Place An Order To Tell Us To Come Next
              Week. Please We Want Only Buyers Who Are Ready To Purchase This Product To Enjoy
              Our SPECIAL PROMO...
            </p>
            <p className=" font-bold italic text-lg text-red-600 text-center">
              AFTER PLACING YOUR ORDER, OUR SALES REP WILL CONTACT YOU TO CONFIRM THE ORDER
            </p>
            <p className=" font-bold italic text-xl text-[#3A82E4] my-2 text-center">
              PAYMENT IS ON DELIVER || FREE DELIVERY NATION WIDE
            </p>
          </div>
          <Form />
          <img src={cod} alt="one year warranty" className="w-[40%]" />
        </section>
        <section className="w-[95%] md:w-[50%] flex flex-col items-center">
          <h4
            className="my-4 w-full bg-[#3A82E4] text-white text-center py-2 font-black text-xl"
          >
            TESTIMONIES FROM OUR CUSTOMERS
          </h4>
          <div className=" flex flex-col items-center gap-3 w-[70%]">
            {testimonies.map((testimony) => (
              <TestimoneyCard testimony={testimony} key={testimony.name} />
            ))}
          </div>
        </section>
        <section className="w-[95%] md:w-[50%]">
          <h4
            className="my-4 w-full bg-[#3A82E4] text-white text-center py-2 font-black text-xl"
          >
            BECOME A DISTRIBUTOR
          </h4>
          <div className="flex flex-col items-center">
            {/* <iframe
              src="https://drive.google.com/file/d/1RUb5YWG3wPZFZKhQj2D1U06Wt5Etxqylc/preview"
              className="w-[90%] h-80 border-2 border-[#3A82E4] dark:border-gray-700 mb-3"
              allow="autoplay"
              title="Fuel Economizer"
            /> */}
            <img src={beeVenon11} alt="main" className="my-2 w-full h-full rounded-md" />
            <div>
              <p className=" text-center text-sm italic">
                Are you ready to transform lives and build a profitable business with the
                revolutionary
                Bee Venom Arthritis Care Cream? This natural solution is changing how Nigerians
                manage arthritis pain and joint discomfort. Join us in bringing relief
                and mobility
                to millions across the nation while securing your financial success.
              </p>
              <h5 className=" text-[#3A82E4] text-center font-bold text-xl my-3">
                Why Choose Bee Venom Arthritis Care Cream Distribution?
              </h5>
            </div>
            <ul className="flex flex-col items-start gap-2">
              <li className=" text-start">
                <span className="font-bold text-lg mr-2">Skyrocketing Demand:</span>
                Arthritis affects millions of Nigerians, leaving them in constant pain and
                limiting their daily activities. Bee Venom Arthritis Care Cream offers
                fast relief and natural recovery, making it a must-have product
                in households across the nation.
              </li>
              <li className=" text-start">
                <span className="font-bold text-lg mr-2">Generous Profit Margins:</span>
                With arthritis being a widespread issue, distributors of this
                life-changing product enjoy high sales volume and lucrative profits,
                turning compassion into cash flow.
              </li>
              <li className=" text-start">
                <span className="font-bold text-lg mr-2">Proven Effectiveness:</span>
                Bee Venom Arthritis Care Cream is trusted by countless users who
                have reclaimed their mobility and joy. As a distributor, you&apos;ll
                represent a product with real results, gaining the trust and
                loyalty of customers.
              </li>
              <li className=" text-start">
                <span className="font-bold text-lg mr-2">A Product with Purpose:</span>
                You&apos;re not just selling a cream; you&apos;re delivering hope and relief to
                those in pain. Make a difference in people&apos;s lives while
                building your business empire.
              </li>
            </ul>
            <div>
              <p className=" font-bold italic text-lg text-red-600 text-center">
                Contact Us on WhatsApp: 📲 +2349019520232 | +2349019520232 And Express Your Interest
              </p>
              <p className=" font-bold italic text-xl text-[#3A82E4] my-2 text-center">
                Why Wait? Take the First Step Toward a Pain-Free Life Today!!!
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Home;
